import { Component, OnInit, ViewChild, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef, NgZone, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import {
  ChallengeService,
  SessionService,
  UTMService,
  EventService,
  SitePageService,
  PosthogService
} from 'src/app/services/';
import { Observable, finalize, switchMap } from 'rxjs';
import { Subject, combineLatest, of } from 'rxjs';
import { takeUntil, skipWhile, delay, tap } from 'rxjs/operators';

@Component({
  selector: 'app-challenge',
  templateUrl: './challenge.component.html',
  styleUrls: ['./challenge.component.scss'],
})
export class ChallengeComponent implements OnInit {
  public static REFEREE_FIRST_NAME: string = 'refFirstName';
  public static REFEREE_LAST_NAME: string = 'refLastName';
  public static REFEREE_EMAIL: string = 'refEmail';
  public static EXISTING_MEMBER: boolean = false;

  constructor(
    private challengeService: ChallengeService,
    private router: Router,
    private route: ActivatedRoute,
    private session: SessionService,
    private titleService: Title,
    private utmService: UTMService,
    // private fb: FormBuilder,
    private eventService: EventService,
    private sitePageService: SitePageService,
    private ref: ChangeDetectorRef,
    private zone: NgZone,
    private posthogService: PosthogService
  ) { }

  errorContent: string = '';
  checked: boolean = false;
  checkboxes: { terms: boolean, sms: boolean } = {
    terms: false,
    sms: false
  }
  challengeActive: boolean = false;
  token: string = '';
  nextPageURL: string = '/challenge/referral';


  //Timer
  days: number = 0;
  hours: number = 0;
  minutes: number = 0;
  seconds: number = 0;
  remainingTimer: number = 0; // total time remaining on timer

  loading: boolean = true;
  isMobile: boolean = false;

  viewOption: string;
  existingMember: boolean = false;

  // Modal
  showSmsModal: boolean = false;

  // User's token
  weshapeToken;

  unsubscribe$ = new Subject();
  signupForm$: Observable<any>;
  smsForm$: Observable<any>;
  processingState$: Observable<any>;

  userFormPayloadData;


  // Posthog Related Items
  experimentFlag;
  prospectEvent: { viewed: boolean, applied: boolean } = {
    viewed: false,
    applied: false
  }


  ngOnInit(): void {
    this.isMobile = window.innerWidth < 768;
    this.viewOption = this.route.snapshot.params.viewOption;

    this.titleService.setTitle('Challenge Application | WeShape');
    this.route.queryParams
      .pipe(
        switchMap((params) => {
          if (params['token']) {
            this.weshapeToken = params['token'];
            return this.challengeService.getChallengeWithToken(params['token']);
          }
          return this.challengeService.getChallenge();
        })
      )
      .subscribe((res) => {
        this.updateValues(res);

        // Identify user in posthog
        if (this.weshapeToken) {
          this.posthogService.identifyUser(this.weshapeToken, {
            name: `${res.firstName} ${res.lastName}`,
            email: res.email
          });
        }

        this.posthogService.posthogInstance.onFeatureFlags( () => {

          // Prevent multiple events due to posthog feature flag listener
          if(!this.prospectEvent.viewed){
            this.eventService.sendEvent(
              { event: 'prospect_viewed_challenge_application' },
              res.email
            );
            this.prospectEvent.viewed = true;
          }
        })

        this.experimentFlag = res.challengeUpsellExperiment || undefined;
      });


    // Initialize listener for possible form changes
    this.signupForm$ = this.challengeService.signupForm$;
    this.smsForm$ = this.challengeService.smsSignupForm$;
    this.processingState$ = this.challengeService.processingState$;

    this.processingState$.pipe(
      delay(350), // delay to wait for other observables to finish updating
      skipWhile(res => !res),
      takeUntil(this.unsubscribe$),
      switchMap(res => {
        if (res.type === 'signup' && res.submitted) {
          return combineLatest([this.signupForm$, of(res)]);
        } else if (res.type === 'smsSignup' && res.submitted) {
          return combineLatest([this.smsForm$, of(res)]);
        } else {
          return of([null, null]);
        }
      }),
      skipWhile(res => {
        return res.some(item => !item)
      }),
      takeUntil(this.unsubscribe$)
    ).subscribe(res => {
      let [form, processingState] = res
      if (form && processingState) {
        let processSignup = processingState.type.indexOf('signup') > -1;
        let processSMS = processingState.type.indexOf('sms') > -1;
        if (processingState.submitted && !processingState.processing) {
          if (processSignup) {
            this.submitForm(form);
          } else if (processSMS) {
            this.submitSMS(form);
          }
        }
      }
    })

    this.utmService.updateUTM({ url: window.location.pathname }).subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isMobile = window.innerWidth < 768;
  }

  updateValues(response) {
    this.loading = false;
    this.challengeActive = response?.active;
    this.days = response?.days;
    this.hours = response?.hours;
    this.minutes = response?.minutes;
    this.seconds = response?.seconds;
    let tempTimer = {
      days: this.days,
      hours: this.hours,
      minutes: this.minutes,
      seconds: this.seconds
    };
    this.remainingTimer = this.calculateTimeRemaining(tempTimer);


    let signupFormData = {
      firstName: response?.firstName,
      lastName: response?.lastName,
      email: response?.email,
      phone_number: response?.phone,
      description: response?.description || '',
    }
    this.challengeService.signupFormBS.next(signupFormData);
  }

  calculateTimeRemaining(timer) {
    // calculate the total time in seconds to return
    let remainingTime = 0;
    if (timer?.days) {
      remainingTime = remainingTime + (timer.days * 24 * 60 * 60)
    }

    if (timer?.hours) {
      remainingTime = remainingTime + (timer.hours * 60 * 60)
    }

    if (timer?.minutes) {
      remainingTime = remainingTime + (timer.minutes * 60)
    }

    if (timer?.seconds) {
      remainingTime = remainingTime + timer?.seconds
    }

    return remainingTime
  }

  changeInput() {
    this.errorContent = '';
  }

  submitForm(data) {
    this.challengeService.processingStateBS.next({ type: 'signup', submitted: true, processing: true, message: '' });

    this.challengeService.updateSignUpForm(data).pipe(
      tap(() => {
        this.userFormPayloadData = data;
      })
    ).subscribe({
      next: (res) => {
        this.session.addSessionParamater(
          ChallengeComponent.REFEREE_FIRST_NAME,
          data.firstName
        );
        this.session.addSessionParamater(
          ChallengeComponent.REFEREE_LAST_NAME,
          data.lastName
        );
        this.session.addSessionParamater(
          ChallengeComponent.REFEREE_EMAIL,
          data.email
        );

        this.weshapeToken = res.weshapeToken;
        this.nextPageURL = res.nextPage;

        this.posthogService.identifyUser(this.weshapeToken, {
          name: `${data.firstName} ${data.lastName}`,
          email: data.email
        });
        if (!res.success) {
          // No Credit Card user will be displayed an error message on the page that will redirect to the appropriate page
          if(res.noCCSubscription) {
            this.eventService.sendEvent(
              { event: 'prospect_applied_to_challenge',
                event_properties: {
                  type: 'no credit card user'
                }
               },
              data.email
            );
            this.challengeService.processingStateBS.next({ type: 'signup', submitted: false, processing: false, message: res.message || '' });
          } else {
            // Lead user who particpated in previous challenge, redirect to promo optin page
            this.eventService.sendEvent(
              { event: 'prospect_applied_to_challenge',
                event_properties: {
                  type: 'returning lead'
                }
               },
              data.email
            );
            this.navigateToRoute(this.nextPageURL);
          }


          // this.router.navigate([res.nextPage], { queryParams: { token: this.weshapeToken, existing: ChallengeComponent.EXISTING_MEMBER } });
        } else if (res.success) {
          this.posthogService.updateEarlyAccessFeatureEnrollment('coach-access', true);

          // Check if there is a upsell redirect test based on nextPageURL
          if (!res.member) {
            if(this.nextPageURL.includes('challenge-upsell')) {
              // Redirect test exists.  Get redirect url to direct users to
              if (this.experimentFlag) {
                this.nextPageURL = this.posthogService.getFeatureFlagPayload(this.experimentFlag)['redirect'];
              }
            }

            this.eventService.sendEvent(
              { event: 'prospect_applied_to_challenge',
                event_properties: {
                  type: 'new lead'
                }
              },
              data.email
            );

          } else {
            this.eventService.sendEvent(
              { event: 'prospect_applied_to_challenge',
                event_properties: {
                  type: 'member'
                }
               },
              data.email
            );
          }

          if (this.showMobileTerms) {
            // Replace url with appropriate next page url if upsell
            this.openModal();
          } else {
            this.navigateToRoute(this.nextPageURL);
          }
        }
      },
      error: (error) => {
        this.errorContent = error.error.message;
        this.challengeService.processingStateBS.next({ type: 'signup', submitted: false, processing: false, message: this.errorContent });

      },
    });
  }

  submitSMS(payload) {
    // Combine payload from signup and sms form
    this.userFormPayloadData = {
      ...this.userFormPayloadData,
      ...payload
    };
    this.challengeService.processingStateBS.next({ type: 'smsSignup', submitted: true, processing: true, message: '' });
    this.challengeService.updateSMSSignUpForm(this.userFormPayloadData).subscribe({
      next: (res) => {
        if (res.success) {
          this.challengeService.processingStateBS.next({ type: '', submitted: false, processing: false, message: '' });
          this.navigateToRoute(this.nextPageURL);
        }
      },
      error: (error) => {
        this.errorContent = error.error.message;
        this.challengeService.processingStateBS.next({ type: 'smsSignup', submitted: false, processing: false, message: this.errorContent });
      },
    });
  }

  routeToQuiz() {
    this.router.navigate(['/quiz']);
  }


  /**
   * Checks url and route the user appropriately
   * @param url Response url to get redirected
   */
  navigateToRoute(url) {
    let link = `${url}?token=${this.weshapeToken}&existing=${ChallengeComponent.EXISTING_MEMBER}`;

    if (url.includes('https')) {
      this.sitePageService.checkAndRedirectUrl(link);
    } else {
      this.router.navigate([url], { queryParams: { token: this.weshapeToken, existing: ChallengeComponent.EXISTING_MEMBER } });
    }
  }


  /** MODALS */
  openModal() {
    this.challengeService.processingStateBS.next({ type: 'smsSignup', submitted: false, processing: false, message: '' });
    this.showSmsModal = true;
  }

  closeModal(event) {
    this.showSmsModal = !event;
    this.challengeService.processingStateBS.next({ type: '', submitted: false, processing: false, message: '' });
    this.navigateToRoute(this.nextPageURL);

    // this.router.navigate([this.nextPageURL], { queryParams: { token: this.weshapeToken, existing: ChallengeComponent.EXISTING_MEMBER } });
  }

  get showMobileTerms() {
    return this.eventService.isUS();
    // if (this.eventService.isUS())
    //   return true;
    // return false;
  }
}
