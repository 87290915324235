import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';


import { HomePageComponent } from './lander/home-page/home-page.component';
import { QuizMainComponent } from './quiz/quiz-main/quiz-main.component';
import { HomeV1Component } from './main-site/v1/home-v1/home-v1.component';
import { HowItWorksV1Component } from './main-site/v1/how-it-works-v1/how-it-works-v1.component';
import { HomeV2Component } from './main-site/v2/home-v2/home-v2.component';
import { HowItWorksV2Component } from './main-site/v2/how-it-works-v2/how-it-works-v2.component';
import { TestimonialsV2Component } from './main-site/v2/testimonials-v2/testimonials-v2.component';
import { TestimonialsV1Component } from './main-site/v1/testimonials-v1/testimonials-v1.component';
import { PrivacyPolicyComponent } from './legal/privacy-policy/privacy-policy.component';
import { DoNotSellComponent } from './legal/do-not-sell/do-not-sell.component';
import { TermsComponent } from './legal/terms/terms.component';
import { GenericURLHandlerComponent } from './generic-urlhandler/generic-urlhandler.component';
import { DemoV1Component } from './workout-demo/demo-v1/demo-v1.component';
import { QuizVersionComponent } from './quiz/quiz-version/quiz-version.component';
import { InfluencerTermsComponent } from './legal/influence-terms/influence-terms.component';


/** CHALLENGE */
import { ChallengeComponent } from './challenge/challenge.component';
import { ChallengeReferralComponent } from './challenge/challenge-referral/challenge-referral.component';
import { ChallengeRulesComponent } from './legal/challenge-rules/challenge-rules.component';
import { ChallengeDefaultComponent } from './challenge/challenge-default/challenge-default.component';
import { ChallengeAComponent } from './challenge/challenge-a/challenge-a.component';
import { ChallengeBComponent } from './challenge/challenge-b/challenge-b.component';
import { ChallengePromoConfirmationComponent } from './challenge/challenge-promo-confirmation/challenge-promo-confirmation.component';
import { VideoSubmissionComponent } from './challenge/video-submission/video-submission.component';
import { ReferralComponent } from './challenge/referral/referral.component';
import { ChallengeSignupComponent } from './challenge/challenge-signup/challenge-signup.component';
import { ChallengeV001Component } from './challenge/challenge-variations/challenge-v001/challenge-v001.component';
import { ChallengeV002Component } from './challenge/challenge-variations/challenge-v002/challenge-v002.component';
import { ChallengeV003Component } from './challenge/challenge-variations/challenge-v003/challenge-v003.component';
import { ChallengeV004Component } from './challenge/challenge-variations/challenge-v004/challenge-v004.component';
import { ChallengeV005Component } from './challenge/challenge-variations/challenge-v005/challenge-v005.component';
import { ChallengeV006Component } from './challenge/challenge-variations/challenge-v006/challenge-v006.component';
import { ChallengeV007Component } from './challenge/challenge-variations/challenge-v007/challenge-v007.component';
import { ChallengeV008Component } from './challenge/challenge-variations/challenge-v008/challenge-v008.component';
import { ChallengeV009Component } from './challenge/challenge-variations/challenge-v009/challenge-v009.component';
import { ChallengeV010Component } from './challenge/challenge-variations/challenge-v010/challenge-v010.component';
import { ChallengeV011Component } from './challenge/challenge-variations/challenge-v011/challenge-v011.component';
import { ChallengeV012Component } from './challenge/challenge-variations/challenge-v012/challenge-v012.component';
import { ChallengeV013Component } from './challenge/challenge-variations/challenge-v013/challenge-v013.component';
import { ChallengeV014Component } from './challenge/challenge-variations/challenge-v014/challenge-v014.component';
import { ChallengeTestimonialsComponent } from './challenge/challenge-testimonials/challenge-testimonials.component';

/** MODULES */
import { MatButtonModule } from "@angular/material/button";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from '../shared/shared.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { RedirectComponent } from './main-site/redirect-component/redirect.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { QuizBypassPagesComponent } from './quiz/quiz-bypass-pages/quiz-bypass-pages.component';


const CHALLLENGE_ITEMS = [
  ChallengeComponent,
  ChallengeReferralComponent,
  ChallengeRulesComponent,
  ChallengeAComponent,
  ChallengeBComponent,
  ChallengePromoConfirmationComponent,
  ChallengeDefaultComponent,
  ChallengeSignupComponent,
  ChallengeV001Component,
  ChallengeV002Component,
  ChallengeV003Component,
  ChallengeV004Component,
  ChallengeV005Component,
  ChallengeV006Component,
  ChallengeV007Component,
  ChallengeV008Component,
  ChallengeV009Component,
  ChallengeV010Component,
  ChallengeV011Component,
  ChallengeV012Component,
  ChallengeV013Component,
  ChallengeV014Component,
  ChallengeTestimonialsComponent
]

@NgModule({
  declarations: [
    HomePageComponent,
    QuizMainComponent,
    HomeV1Component,
    HowItWorksV1Component,
    HomeV2Component,
    HowItWorksV2Component,
    TestimonialsV2Component,
    TestimonialsV1Component,
    PrivacyPolicyComponent,
    DoNotSellComponent,
    TermsComponent,
    GenericURLHandlerComponent,
    ReferralComponent,
    DemoV1Component,
    VideoSubmissionComponent,
    InfluencerTermsComponent,
    QuizVersionComponent,
    CHALLLENGE_ITEMS,
    RedirectComponent,
    QuizBypassPagesComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSkeletonLoaderModule.forRoot(),
    InlineSVGModule.forRoot()
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PagesModule { }
