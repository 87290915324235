<div class="challenge-variation challenge-variation-bg-black challenge-v14">
    <section class="challenge-hero">
        <div class="container-xl">
            <div class="challenge-hero-header">
                <div class="hero-logo"><img src="/assets/images/Weshape-Logo-White.svg" alt="Weshape Logo"></div>
                <div class="hero-timer">
                    <app-timer [main_txt]="'REGISTRATION ENDS IN'" [main_theme_color]="'white-transparent'" [corners]="'border-round'" [is_present]="'d-none'" [main]="'timer--block'" [remainingTime]="timer" [fullTimeName]="true" [numDigits]="'2'"></app-timer>
                </div>
            </div>
        </div>
        <div class="container-large">
            <div class="challenge-hero-content">
                <header><span>FREE</span>FEEL GOOD CHALLENGE</header>
                <p class="challenge-hero_subheader text-bayvon">It's time to do fitness, differently.</p>
            </div>
        </div>
    </section>
    <section>
        <header class="section-header challenge-variation-text-50 text-bayvon">join today to access:</header>
        <div class="container-large challenge-access">
            <div>
                <img src="/assets/images/challenge-optin/workouts2.png" alt="Customized Workouts">
                <p>4 weeks of customized workouts WITH YOUR PERSONAL AI COACH!</p>
            </div>
            <div>
                <img src="/assets/images/challenge-optin/community_zoom.png" alt="Community Weekly Live Calls">
                <p>weekly live coaching for support & inspiration!</p>
            </div>
            <div>
                <img src="/assets/images/challenge-optin/challenge-progress.png" alt="Challenge Progress">
                <p>Daily curriculum that is proven to work!</p>
            </div>
        </div>
        <button class="challenge-btn challenge-btn-white challenge-variation-width-sm my-sm-1 my-lg-5" (click)="scrollTo()">Join Now!</button>
    </section>
    <section>
        <header class="section-header challenge-variation-text-50 text-bayvon border-white">So what makes this challenge different?</header>
        <div class="container-large">
            <p>At WeShape, we believe that every<b><i>body</i></b> deserves to feel healthy and happy!</p>
            <p>And yet - despite pouring more time, energy and money into fitness, it seems that people are becoming less healthy and less happy than ever before.</p>
            <p>And so, we decided that it was time to shape a new approach....</p>
            <p class="challenge-variation-text-28"><b><i>It's time to do fitness differently.</i></b></p>
            <p>It's not about punishment or pushing harder. It's about reconnecting with what your body truly needs to feel good - inside and out.</p>
            <p>At WeShape, we focus on redefining our relationship with movement, cultivating self-connection, and prioritizing our long-term well-being, because that's how we believe everybody can feel its best.</p>
        </div>
    </section>
    <section class="challenge-variation-bg-gray">
        <header class="section-header challenge-variation-text-40 text-bayvon">And, we can promise you that it works.<br> We know because our WeShape Community is experiencing it every day:</header>
        <app-challenge-testimonials></app-challenge-testimonials>

    </section>
    <section>
        <button class="challenge-btn challenge-btn-white challenge-variation-width-sm my-sm-1 my-lg-5" (click)="scrollTo()">Join Now!</button>
        <header class="section-header challenge-variation-text-50 border-white text-bayvon">are you ready to take a step towards the healthiest, happiest you?</header>
        <div class="container-large challenge-list">
            <ol>
                <li>Click the Join Now button or scroll down to the sign up form, and enter in your first name, last name and email to get started! </li>
                <li>There is <b>NO CREDIT CARD REQUIRED</b>, no strings attached, no long term commitment. This Challenge is completely free to join*.</li>
                <li>Once you have signed up, wait for an email from our team with how to login, instructions to download our App (encouraged, but not required!), and further details about how this Challenge will work!</li>
            </ol>
            <p class="text-small">* Non-members can join one free challenge every six months (up to two per year). Members enjoy unlimited access to all challenges as part of their membership.</p>
        </div>
    </section>
    <section>
        <div #signupForm class="challenge-variation-content-signup w-100 py-5">
            <p class="challenge-variation-text-50 text-bayvon">Our Feel Good Challenge Starts: Monday, January 13th, 2025</p>
            <p class="challenge-variation-text-50 text-bayvon">Sign Up For Free Before You Miss Out!</p>
            <div elementInView (isVisible)="_isMobile && formVisible($event)" class="challenge-variation-width-md mx-auto">
                <div class="my-3">
                    <app-timer [main_txt]="'REGISTRATION ENDS IN'" [main_theme_color]="'lighter-blue'" [corners]="'border-round'" [is_present]="'d-none'" [main]="'timer--block'" [remainingTime]="timer" [fullTimeName]="true" [numDigits]="'2'"></app-timer>
                </div>
                <div class="challenge-variation-white-bg text-black">
                    <p>Sign Up</p>
                    <app-challenge-signup></app-challenge-signup>
                </div>
            </div>
        </div>
    </section>
    <button #floatingButton *ngIf="_isMobile && showButton" class="challenge-btn floating-btn" type="button" (click)="scrollTo()">Sign Up</button>
</div>