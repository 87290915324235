<ng-container *ngIf="!challengeActive && loading">
  <div class="content-loading">
    <ngx-skeleton-loader count="100" appearance="line"></ngx-skeleton-loader>
  </div>
</ng-container>

<div *ngIf="challengeActive; else noChallenge">
  <div>
    <ng-container [ngSwitch]="viewOption">
      <ng-container *ngSwitchCase="'v001'">
        <app-challenge-v001 [timer]="remainingTimer"></app-challenge-v001>
      </ng-container>
      <ng-container *ngSwitchCase="'v002'">
        <app-challenge-v002 [timer]="remainingTimer"></app-challenge-v002>
      </ng-container>
      <ng-container *ngSwitchCase="'v003'">
        <app-challenge-v003 [timer]="remainingTimer"></app-challenge-v003>
      </ng-container>
      <ng-container *ngSwitchCase="'v004'">
        <app-challenge-v004 [timer]="remainingTimer"></app-challenge-v004>
      </ng-container>
      <ng-container *ngSwitchCase="'v005'">
        <app-challenge-v005 [timer]="remainingTimer"></app-challenge-v005>
      </ng-container>
      <ng-container *ngSwitchCase="'v006'">
        <app-challenge-v006 [timer]="remainingTimer" [isMobile]="isMobile"></app-challenge-v006>
      </ng-container>
      <ng-container *ngSwitchCase="'v007'">
        <app-challenge-v007 [timer]="remainingTimer" [isMobile]="isMobile"></app-challenge-v007>
      </ng-container>
      <ng-container *ngSwitchCase="'v008'">
        <app-challenge-v008 [timer]="remainingTimer" [isMobile]="isMobile"></app-challenge-v008>
      </ng-container>
      <ng-container *ngSwitchCase="'v009'">
        <app-challenge-v009 [timer]="remainingTimer" [isMobile]="isMobile"></app-challenge-v009>
      </ng-container>
      <ng-container *ngSwitchCase="'v010'">
        <app-challenge-v010 [timer]="remainingTimer" [isMobile]="isMobile"></app-challenge-v010>
      </ng-container>
      <ng-container *ngSwitchCase="'v011'">
        <app-challenge-v011 [timer]="remainingTimer" [isMobile]="isMobile"></app-challenge-v011>
      </ng-container>
      <ng-container *ngSwitchCase="'v012'">
        <app-challenge-v012 [timer]="remainingTimer" [isMobile]="isMobile"></app-challenge-v012>
      </ng-container>
      <ng-container *ngSwitchCase="'v013'">
        <app-challenge-v013 [timer]="remainingTimer" [isMobile]="isMobile"></app-challenge-v013>
      </ng-container>
      <ng-container *ngSwitchCase="'v014'">
        <app-challenge-v014 [timer]="remainingTimer" [isMobile]="isMobile"></app-challenge-v014>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <app-challenge-v006 [timer]="remainingTimer" [isMobile]="isMobile"></app-challenge-v006>
      </ng-container>
    </ng-container>
  </div>
</div>

<app-sms-optin *ngIf="showSmsModal" header="Want help staying on track?" content="Sign up for SMS notifications and get reminders, tips, and encouragement straight to your phone!" [errorContent]="errorContent" [signupForm]="smsForm$" [processingState]="processingState$" (smsPayload)="submitSMS($event)" (closeModalState)="closeModal($event)"></app-sms-optin>


 <ng-template #noChallenge>
  <div class="challenge-ended text-center m-auto">
    <div class="container my-3">
      <div class="text-56 mx-3 mx-md-0">
        Sorry, our 30 Day Feel Better Challenge is already full... :-(
      </div>
      <app-timer
        [container_color]="'white-container'"
        [main_theme_color]="'white-promo '"
        [days]="days"
        [hours]="hours"
        [minutes]="minutes"
        [seconds]="seconds"
      ></app-timer>
      <div class="text-24">
        You will have the opportunity to join the next one, so make sure to stay subscribed to our mailing list so you can be notified when the next one happens!
      </div>
      <div class="text-24">
        If you would like to try our Personalized At Home Workouts for free, click below to get started!
      </div>
      <div class="m-1">
        <a class="btn-blue" (click)="routeToQuiz()" role="button">Try a Workout Today</a>
      </div>
    </div>
  </div>
</ng-template>


<app-footer></app-footer>
